
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import SelectFromPlatform from "../components/SelectFromPlatform.vue";
import SelectToPlatform from "../components/SelectToPlatform.vue";
import SelectConfirm from "../components/SelectConfirm.vue";
import { ElMessageBox } from "element-plus";
interface Result {
  data: any;
}

@Options({
  components: {
    SelectFromPlatform,
    SelectToPlatform,
    SelectConfirm,
  },
})
export default class PackagePage extends Vue {
  active = 0;
  grandPrice = 0;
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  url = `${process.env.VUE_APP_BASE_URL}/`;
  listData = [];
  connectData: any = [];
  fromType = "";
  nextDate = null;
  price = null;
  applicationIds = {};
  callbacks = {};
  connectExist: any = [];
  //
  drawerSelected = false;
  isActive = true;
  next() {
    if (this.active++ > 4) {
      this.active = 0;
    }
  }
  async created() {
    await this.$store.dispatch("setLoading", true, { root: true });
    const accounts = await this.$store.state.auth.dataLogin.account;   
    let accountStore = localStorage.getItem("account");
    
    if (accountStore) {
      let acc: any = {};
      for (acc of accounts) {
        if (acc["_id"] === accountStore && acc.connectionData.length) {
          await this.$router.push({ name: "DashboardPage" });

        } else this.isActive = false;
      }
    }
    await Promise.all([this.getListPlatform(), this.getListConnect()]);
    this.handleActiveDefault();
        await this.$store.dispatch("setLoading", false, { root: true });
  }

  handleActiveDefault() {
    for (let connect of this.connectData) {
      if (connect.connectFrom) {
        this.fromType = connect.type;
        this.active++;
      }
      if (connect.connectTo) {
        return this.active++;
      }
    }
  }

  async getListPlatform() {
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: any = await axios.get("admin/platform", {
      headers: this.headers,
    });
    if (res) {
      this.listData = res.data;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  async getListConnect() {
    let res: any = await axios.get("user/connect/list", {
      headers: this.headers,
    });
    if (res.data) {
      this.applicationIds = res.applicationIds;
      this.callbacks = res.callbacks;
      let total = 0;
      let type: any = [];
      for (let connect of res.data) {
        if (connect.connectTo) {
          this.connectExist.push(connect.type);
          if (type.includes(connect.type)) {
            connect["subprice"] = +connect.price.toFixed(2);
          } else {
            type.push(connect.type);
            connect["subprice"] = connect.price;
          }
          total = total + +connect["subprice"];
             if (
            this.connectExist.filter((x: any) => x === connect.type).length == 1
          ) {
            connect["discount"] = 0;
          }
          if (
            this.connectExist.filter((x: any) => x === connect.type).length > 1 &&
            this.connectExist.filter((x: any) => x === connect.type).length <= 3
          ) {
            connect["discount"] = connect.platform.discountlv1;
          }
          if (
            this.connectExist.filter((x: any) => x === connect.type).length > 3
          ) {
            connect["discount"] = connect.platform.discountlv2;
          }
        }
      }
      this.grandPrice = total;
      this.connectData = res.data;
      this.connectExist = []
    } else {
      this.active = 0;
    }
  }

  async handleFrom(type: string, data: any) {
    this.fromType = type;
    await this.getListConnect();
    this.active++;
  }
  handleTo(data: any) {
    this.getListConnect();
  }
  handleActive() {
    this.getListConnect();
    let selected = 0;
    for (let connect of this.connectData) {
      if (connect.connectTo) {
        selected++;
      }
    }
    if (selected > 0) {
      this.active++;
    } else {
      this.$message.warning("Please select more than one target system !!!");
    }
  }
  handleDeleteConnect() {
    let res: Result;
    ElMessageBox.confirm("Do you want to re select connect?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    }).then(async () => {
      res = await axios.post(
        "user/connect/deleteMany",
        { account: localStorage.getItem("account") },
        { headers: this.headers }
      );
      if (res) {
        this.active = 0;
        this.connectData = [];
      }
    });
  }
  handleViewConnect() {
    this.drawerSelected = true;
  }
  nextStep(data: any) {
    this.active++;
    this.nextDate = data.nextDate;
    this.price = data.price;
  }
  handleHomePage() {
    this.$router.push({ name: "DashboardPage" });
  }
  hideSummary() {
    this.drawerSelected = false;
  }
  handleGoBack() {
    this.active--;
  }
}
